<template>
<div class="recruit-box left clearfix">
  <Header></Header>
  <!--顶部banner开始-->
  <div class="aboutus-banner left"> <img src="~images/banner/xinxiangmuzixun.jpg" width="100%" /> </div>

                <div class="contact-content width-bai left clearfix">
                <div class="contact-contents center clearfix" style="padding-top:30px;">

                <h1 style="margin-left:30px;">改造（迁移）</h1>
                <div class="recruit-form">
                    <Form :model="formItem" :label-width="100" :label-position="labelPosition" :rules="ruleValidate" ref="formItem" style="width:70%;margin-left:15%;">
                        <FormItem label="联系姓名" prop="name">
                            <Input v-model="formItem.name" style="margin-left:-60px;" placeholder="请输入姓名"></Input>
                        </FormItem>
                        <FormItem label="联系电话" prop="tel">
                            <Input v-model="formItem.tel" style="margin-left:-60px;" placeholder="请输入您的常用联系电话"></Input>
                        </FormItem>

                        <FormItem label="施工地址" prop="address">
                            <Input v-model="formItem.address" style="margin-left:-60px;" placeholder="请输入您的施工地址"></Input>
                        </FormItem>

                        <FormItem label="设备现状" prop="present">
                            <Input v-model="formItem.present" type="textarea" :autosize="{minRows: 5,maxRows: 5}" style="margin-left:-60px;" placeholder="请输入现状"></Input>
                        </FormItem>

                        <FormItem label="达成效果" prop="effect" style="margin-top:20px;">
                            <Input v-model="formItem.effect" type="textarea" :autosize="{minRows: 5,maxRows: 5}" style="margin-left:-60px;" placeholder="请输入预期达成的效果"></Input>
                        </FormItem>

                        <FormItem label="特殊说明" prop="content" style="margin-top:20px;">
                            <Input v-model="formItem.content" style="margin-left:-60px;" type="textarea" :autosize="{minRows: 10,maxRows: 10}" placeholder="请输入说明"></Input>
                        </FormItem>

                    <div class="form-button" style="margin-bottom:30px;">
                        <FormItem> <Button type="primary" @click="handleSubmit('formItem')" style="width:120px;">立即咨询</Button> </FormItem>
                    </div>
                    </Form>
                </div>
                </div>
                </div>
  <div class="left clearfix width-bai"> <Footer></Footer> </div>
  <contact-call></contact-call>
</div>
</template>

<style>
    #propess{display:none;width:100%;}
    .table_title{font-weight:bold;}
    .project_text{width:100%;}
    .project_text table tr td{width:25%;height:50px;border:1px solid #eee;text-align:center;font-size:14px;line-height:25px;}
    .project_text table{width:90%;margin-left:5%;margin-top:50px;}
    h2{line-height:50px;text-align:center;font-size:16px;border:1px solid #eee;margin-top:100px;}
    .ivu-form-item-content{margin-top:10px;}
    .ivu-form-item-error-tip{margin-left:0px;line-height:20px;margin-left:-60px;}
    .ivu-form .ivu-form-item-label{padding-top:3px;font-weight:bold;margin-top:10px;}
    .recruit-box h1{font-size:15px;border-left:5px solid rgb(242,151,0);padding-left:10px;margin-bottom:40px;}
    .contact-text{width:100%;min-height:800px;margin-top:20px;}
    .contact-contents{min-height:900px;width:1440px;margin-top:10px;font-size:16px;box-shadow: 0px 2px 5px #cccccc;margin-top:50px;padding-bottom:50px;}
    .contact-content{min-height:900px;}
    .form-label input{width:23%;margin-left:1%;margin-right:1%;line-height:30px;border:1px solid #ddd;border-radius:5px;padding-left:5px;}
    .form-button{width:100%;height:60px;margin-top:30px;text-align:center;}
    .form-button Button{margin-top:50px;margin-bottom:50px;margin-right:20px;}
    .form-label{width:100%;line-height:40px;}
    .form-label label{width:23%;float:left;margin-left:1%;margin-right:1%;text-align:center;font-weight:bold;font-size:15px;}
    .form-title{width:100%;line-height:60px;font-weight:bold;font-size:15px;}
</style>
<script>
import $ from 'jquery'
import ContactCall from '../index/ContactCall.vue'
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default {
        data () {
            
            return {
                labelPosition:'top',
                data:'',
                formItem: {
                    name:'',
                    tel:'',
                    address:'',
                    present:'',
                    effect:'',
                    content:''
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '请填写联系人', trigger: 'blur' }
                    ],
                    tel: [
                        { required: true, message: '请填写联系电话', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '请填写施工地址', trigger: 'blur' }
                    ],
                    present: [
                        { required: true, message: '请填写现状', trigger: 'blur' }
                    ],
                    effect: [
                        { required: true, message: '请填写希望达成的效果', trigger: 'blur' }
                    ]


                }

            }
            

        },
        mounted(){
            
        },
        components:{
            ContactCall,
            Header,
            Footer
        },

methods: {
  handleSubmit (name) {
       $('#propess').hide();
      this.$refs[name].validate((valid) => {
          if (valid) {
              this.formItem.token=this.$store.state.token;
              this.formItem.username=eval('(' +this.$store.state.userinfo+ ')').username;
              this.formItem.types=3;
              var param=JSON.stringify(this.formItem);
              param = eval('(' + param + ')');
              this.$axios({method:'post',url:window.configs.svcProt + "://" + window.configs.svcIpPort + '/insert_install.html',params:param}).then(res=>{
                  console.log(res.data);
                 if(res.data.code=='000'){
                    this.$Modal.success({
                          title: '温馨提示',
                          content: '提交成功，客服会尽快联系您，请耐心等待···',
                           onOk: () => {
                              location.href="/index"
                          }
                      });
                      
                 }
              });
              
          } 
      })
  }
            
}
}
</script>

